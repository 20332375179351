import React from 'react'

import Page from 'components/Page'

import setDisplayName from 'helpers/setDisplayName'

export default setDisplayName('Home')(() => (
  <Page
    id='home'
    header='Home'>
    <p>Welcome to our little home on the web.</p>
  </Page>
))
