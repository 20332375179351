import React from 'react'

import { NavLink } from 'react-router-dom'

import setDisplayName from 'helpers/setDisplayName'

export default setDisplayName('MainNav')(() => (
  <nav>
    <ul>
      <li className='nav-li-home'>
        <NavLink exact to='/'>Home</NavLink>
      </li>
      <li className='nav-li-games'>
        <NavLink to='/games'>Games</NavLink>
      </li>
      <li className='nav-li-about'>
        <NavLink to='/about'>About</NavLink>
      </li>
      <li className='nav-li-contact'>
        <NavLink to='/contact'>Contact</NavLink>
      </li>
    </ul>
  </nav>
))
