import React from 'react'

import Link from 'components/Link'

import TwitterImage from 'assets/social_twitter_icon.png'
import FacebookImage from 'assets/social_facebook_icon.png'
import InstagramImage from 'assets/social_instagram_icon.png'
import PinterestImage from 'assets/social_pinterest_icon.png'
import TumblrImage from 'assets/social_tumblr_icon.png'

import setDisplayName from 'helpers/setDisplayName'

export default setDisplayName('Social')(() => (
  <section id='social'>
    <nav>
      <ul>
        <li>
          <Link to='https://twitter.com/ramblingindie' target='_blank'><img src={TwitterImage} alt='Twitter' /></Link>
        </li>
        <li>
          <Link to='https://www.facebook.com/ramblingindiegames/' target='_blank'><img src={FacebookImage} alt='Facebook' /></Link>
        </li>
        <li>
          <Link to='https://www.instagram.com/ramblingindiegames/' target='_blank'><img src={InstagramImage} alt='Instagram' /></Link>
        </li>
        <li>
          <Link to='https://www.pinterest.com/ramblingindiegames/' target='_blank'><img src={PinterestImage} alt='Pinterest' /></Link>
        </li>
        <li>
          <Link to='https://ramblingindie.tumblr.com/' target='_blank'><img src={TumblrImage} alt='Tumblr' /></Link>
        </li>
      </ul>
    </nav>
  </section>
))
