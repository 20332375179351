import React from 'react'

import setDisplayName from 'helpers/setDisplayName'

export default setDisplayName('Page')(({ id, header, children }) => (
  <section id={id}>
    <header>{header}</header>
    <article>
      {children}
    </article>
  </section>
))
