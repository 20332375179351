import React from 'react'

import setDisplayName from 'helpers/setDisplayName'

export default setDisplayName('Header')(() => (
  <header>
    <h1>Rambling Indie Games, LLC</h1>
    <h3>Small indie game development house, that never sleeps and does what we love. &hearts;</h3>
  </header>
))
