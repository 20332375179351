import React from 'react'

import Link from 'components/Link'
import Page from 'components/Page'

import setDisplayName from 'helpers/setDisplayName'

export default setDisplayName('Games')(() => (
  <Page
    id='games'
    header='Games'>
    <p>We primarily make games. You can check out the games we have released using the links below.</p>
    <div id='prod'>
      <p>Released:</p>
      <ul>
        <li>
          <Link to='https://ramblingindiegames.itch.io/gelda-tvgamejam-edition' target='_blank'>Gelda - #TVGameJam Edition</Link>
        </li>
        <li>
          <Link to='https://play.google.com/store/apps/details?id=games.ramblingindie.retrocolorbar_free&hl=en_US' target='_blank'>Retrocolorbar</Link>
        </li>
        <li>
          <Link to='https://play.google.com/store/apps/details?id=games.ramblingindie.looneypopfull' target='_blank'>Looney Pop</Link>
        </li>
      </ul>
    </div>

    <div id='dev'>
      <p>In Development:</p>
      <ul>
        <li>10% &mdash; Tiny96</li>
        <li>80% Ufo Oh-no</li>
        <li>60% Unicornia</li>
        <li>15% Gelda - The Complete Adventure</li>
      </ul>
    </div>
    <div id='donate'>
      <p>You can help us out by buying us a ko-fi or two!</p>
      <ul>
        <li>
          <Link to='https://www.ko-fi.com/richardmarks' target='_blank'>Buy a Ko-Fi for Richard</Link>
        </li>
        <li>
          <Link to='https://www.ko-fi.com/falynevarger' target='_blank'>Buy a Ko-Fi for Rachel</Link>
        </li>
      </ul>
    </div>
  </Page>
))
