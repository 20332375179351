import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Header from 'components/Header'
import Footer from 'components/Footer'
import MainNav from 'components/MainNav'
import Social from 'components/Social'

import Home from 'pages/Home'
import Games from 'pages/Games'
import About from 'pages/About'
import Contact from 'pages/Contact'
import NotFound from 'pages/NotFound'

import * as serviceWorker from 'helpers/serviceWorker'

import 'styles/index.css'

const App = () => (
  <Router>
    <div>
      <Header />
      <main>
        <MainNav />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/games' component={Games} />
          <Route exact path='/about' component={About} />
          <Route exact path='/contact' component={Contact} />
          <Route component={NotFound} />
        </Switch>
      </main>

      <Social />
      <Footer />
    </div>
  </Router>
)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
