import React from 'react'

import Link from 'components/Link'
import Page from 'components/Page'

import setDisplayName from 'helpers/setDisplayName'

export default setDisplayName('About')(() => (
  <Page
    id='about'
    header='About'>
    <p>Rambling Indie Games, LLC was founded June 2017 by Richard and Rachel Marks.</p>
    <p>We are a husband and wife team with a passion for great games.</p>
    <h3>Mission</h3>
    <p>Our mission is to do what we love. &hearts;</p>
    <h3>Meet The Team</h3>
    <div className='columns'>
      <div className='column'>
        <p>Richard Marks &ndash; @ycawmoycbw</p>
        <p>
          Richard is the Principal Software Architect.<br />
          He is a "veteran" programmer with 30 years of experience building games and software for a
          plethora of platforms in more programming languages than most have even heard of.
        </p>
        <p>
          Richard has a passion for all things related to game development and software architecture.
          He loves to teach others and has given his time to countless individuals to spread his knowledge
          of software development.
        </p>
        <p>
          A father of three wonderful children, somehow he finds the time to develop games, play guitar,
          compose music digitally, and enjoy skateboarding &hellip; and of course, playing video games.
        </p>

        <p>Roles at the Studio</p>
        <ul>
          <li>Principal Software Architect</li>
          <li>Project Manager</li>
          <li>Sound Designer</li>
          <li>Music Composer</li>
          <li>Pixel Artist</li>
          <li>3D Modeler</li>
          <li>Graphics Designer</li>
          <li>Web Developer</li>
        </ul>

        <p>Around the web</p>
        <ul className='links'>
          <li>
            <Link to='http://ccpssolutions.com/' target='_blank'>Richard&apos;s Webpage</Link>
          </li>
          <li>
            <Link to='https://twitter.com/ycawmoycbw' target='_blank'>Richard @ Twitter</Link>
          </li>
          <li>
            <Link to='https://github.com/RichardMarks' target='_blank'>Richard @ GitHub</Link>
          </li>
          <li>
            <Link to='https://www.artstation.com/ubervestigium' target='_blank'>Richard @ ArtStation</Link>
          </li>
          <li>
            <Link to='https://www.deviantart.com/ubervestigium' target='_blank'>Richard @ DeviantArt</Link>
          </li>
        </ul>
      </div>

      <div className='column'>
        <p>Rachel Marks &ndash; @falynevarger</p>
        <p>
          Rachel is the Senior Art Director.<br />
          She is a self taught, work from home, art mom who loves to draw comics, characters, and create content for video games!
        </p>
        <p>
          Rachel freelances full time, working with several comic studios as a pencil/ink artist
          and colorist, and create character and environment designs, as well as production art,
          for small game studios, and does personal commissions on the side.
        </p>
        <p>
          Despite her hectic schedule, she does her best to keep herself available for new work
          so if you have something in mind feel free to email her.
        </p>
        <p>Roles at the Studio</p>
        <ul>
          <li>Senior Art Director</li>
          <li>Social Media Promo / Public Relations</li>
          <li>Project Manager</li>
          <li>Character Artist</li>
          <li>Environment Artist</li>
          <li>Graphics Designer</li>
          <li>Puzzle &amp; Level Designer</li>
          <li>Music Composer</li>
        </ul>

        <p>Around the web</p>
        <ul className='links'>
          <li>
            <Link to='https://blurredcolors.blogspot.com/' target='_blank'>Rachel&apos;s Blog</Link>
          </li>
          <li>
            <Link to='https://twitter.com/falynevarger' target='_blank'>Rachel @ Twitter</Link>
          </li>
          <li>
            <Link to='https://www.instagram.com/falynevarger/' target='_blank'>Rachel @ Instagram</Link>
          </li>
          <li>
            <Link to='https://www.deviantart.com/falynevarger' target='_blank'>Rachel @ DeviantArt</Link>
          </li>
          <li>
            <Link to='https://www.artstation.com/falynevarger' target='_blank'>Rachel @ ArtStation</Link>
          </li>
        </ul>
      </div>
    </div>

    <h3>History</h3>
    <p>Rambling Indie Games, LLC was founded June 2017 by Richard and Rachel Marks.</p>
    <p>
      Before embarking on this new and exciting adventure, the creative duo had been developing games together
      under the name of Bang Bang Attack Studios.
      The two released many titles for android and web portals such as Kongregate and GameJolt.
    </p>
  </Page>
))
