import React from 'react'

import setDisplayName from 'helpers/setDisplayName'

export default setDisplayName('Footer')(() => (
  <footer>
    <p>All content unless otherwise specified is &copy; Copyright 2019, Rambling Indie Games, LLC. All rights
      reserved.</p>
    <p>Designed with &hearts; by Richard Marks</p>
  </footer>
))
