import React from 'react'

import Page from 'components/Page'

import setDisplayName from 'helpers/setDisplayName'

export default setDisplayName('NotFound')(() => (
  <Page
    id='notfound'
    header='404 Not Found'>
    <p>The page you are looking for is not here.</p>
  </Page>
))
