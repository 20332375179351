import React from 'react'

import Page from 'components/Page'

import setDisplayName from 'helpers/setDisplayName'

export default setDisplayName('Contact')(() => (
  <Page
    id='contact'
    header='Contact'>
    <p>For all inquiries, please use the email below as your first choice for reaching us.</p>
    <div>
      Email: contact@ramblingindie.games
    </div>
    <p />
    <div>
      <div>Mailing Address:</div>
      <address>Rambling Indie Games, LLC</address>
      <address>1717 W Kirby Ave</address>
      <address>Champaign, IL 61821</address>
    </div>
    <p />
    <div>
      Phone: 1+ (281) 769-2776
    </div>
  </Page>
))
