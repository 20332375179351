import React from 'react'

import { Link } from 'react-router-dom'

import setDisplayName from 'helpers/setDisplayName'

export default setDisplayName('Link')(({
  children,
  to,
  target,
  ...other
}) => {
  if (target === '_blank') {
    return <a href={to} target='_blank' rel='noopener noreferrer'>{children}</a>
  } else {
    return <Link to={to} {...other}>{children}</Link>
  }
})
